import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The data fetching logic is split from the rest of the code for better tree-shaking,
i.e. so that it does not get packaged into your code unless it is used.
The SDK is otherwise unconcerned with how you get data from the blockchain.`}</p>
    <p>{`This class contains static methods for constructing instances of pairs and tokens
from on-chain data. It cannot be constructed.`}</p>
    <h1 {...{
      "id": "static-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#static-methods",
        "aria-label": "static methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Static Methods`}</h1>
    <h2 {...{
      "id": "fetchtokendata",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fetchtokendata",
        "aria-label": "fetchtokendata permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`fetchTokenData`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`async fetchTokenData(
  chainId: ChainId,
  address: string,
  provider = getDefaultProvider(getNetwork(chainId)),
  symbol?: string,
  name?: string
): Promise<Token>
`}</code></pre>
    <p>{`Initializes a class instance from a chainId and token address, if the decimals of the token are unknown and cannot be fetched externally. Decimals are fetched via an `}<a parentName="p" {...{
        "href": "https://github.com/ethers-io/ethers.js/"
      }}>{`ethers.js`}</a>{` v5 provider. If not passed in, a default provider is used.`}</p>
    <h2 {...{
      "id": "fetchpairdata",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fetchpairdata",
        "aria-label": "fetchpairdata permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`fetchPairData`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`async fetchPairData(
  tokenA: Token,
  tokenB: Token,
  provider = getDefaultProvider(getNetwork(tokenA.chainId))
): Promise<Pair>
`}</code></pre>
    <p>{`Initializes a class instance from two Tokens, if the pair’s balances of these tokens are unknown and cannot be fetched externally. Pair reserves are fetched via an `}<a parentName="p" {...{
        "href": "https://github.com/ethers-io/ethers.js/"
      }}>{`ethers.js`}</a>{` v5 provider. If not passed in, a default provider is used.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      